import React, { useState, useEffect } from 'react';
import SituationItem from './SituationItem';
import situationsData from './../../cr-data/situations.json';
import Pagination from './Pagination';

const SituationList = ({ filters }) => {
  const [situations, setSituations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    try {
      let filteredSituations = situationsData.filter(situation => {
        // check if situation is active
        const now = new Date();
        return new Date(situation.validityPeriod.startTime) <= now && new Date(situation.validityPeriod.endTime) >= now;
      });
   

    // Apply filters for participant
    if (filters.participant) {
      filteredSituations = filteredSituations.filter(situation => situation.participant === filters.participant);
    }

    // filtering by date range
    if (filters.date) {
      filteredSituations = filteredSituations.filter(situation => 
        new Date(situation.validityPeriod.startTime) <= new Date(filters.date) &&
        new Date(situation.validityPeriod.endTime) >= new Date(filters.date)
      );
    }

    // filters quays and lines
    if (filters.quay) {
      filteredSituations = filteredSituations.filter(situation =>
        situation.affects.some(affect => affect.quay && affect.quay.name.includes(filters.quay))
      );
    }

    setSituations(filteredSituations);
    } catch (err) {
      setError('Failed to fetch situations.');
      console.error(err);
    }
    setIsLoading(false);
  }, [filters, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) return <p>Laster...</p>;
  if (error) return <p>Feil: {error}</p>;

  const renderedSituations = situations.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map(situation => (
    <SituationItem key={situation.id} situation={situation} />
  ));

  return (
    <div className="situation-list-container">
      {renderedSituations}
      <Pagination
        currentPage={currentPage}
        totalItems={situations.length}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SituationList;