import React from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

const SaveToAudio = ({ text, fromPlace, toPlace, travelDate }) => {
    const generateMp3 = async () => {
        const formattedDate = format(parseISO(travelDate), 'dd-MM-yy-HH-mm', { locale: nb });
        const filename = `reiseplan ${fromPlace} - ${toPlace} ${formattedDate}`;
        
        try {
            const response = await axios.post(
                'https://movai.no/savetoaudio-proxy.php',
                { text, filename },
                { responseType: 'blob' }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.mp3`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error generating MP3:', error);
        }
    };

    return (
        <button onClick={generateMp3} aria-label="Download MP3" className="travelplaner-btn">
            <p>Lagre lydfil</p>
            <img className="travelplaner-btn-icon" src="./images/travelplanner/download-white.png" alt="Last ned lydfil" />
        </button>
    );
};

export default SaveToAudio;
