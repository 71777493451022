import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../../auth/AuthContext';
import useIsMobile from './useIsMobile'; // Adjust the path as needed
import './../../styles/Header.css';
import { useState } from 'react';

const Header = () => {
    const { token, logout } = useAuth();
    const isMobile = useIsMobile();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="App-header">
            <a className="header-logo-link" href="/"><h1>movai</h1></a>
            <nav>
                {isMobile ? (
                    <div>
                        <ul className="desktop-menu">
                            <li><Link to="/kart">Kart</Link></li>
                            <li><Link to="/">Reise</Link></li>
                            <li><Link to="/avgangsskilt">Avgang</Link></li>
                            <button className="menu-button" onClick={toggleMenu}>
                                ☰
                            </button>
                        </ul>
                        {isMenuOpen && (
                            <ul className="mobile-menu">
                                <li><Link to="/avvik" onClick={toggleMenu}>Avvik</Link></li>
                                <li><Link to="/om-movai" onClick={toggleMenu}>Om</Link></li>
                                
                            </ul>
                        )}
                    </div>
                ) : (
                    <ul className="desktop-menu">
                        <li><Link to="/kart">Kart</Link></li>
                        <li><Link to="/">Reise</Link></li>
                        <li><Link to="/avgangsskilt">Avgang</Link></li>
                        <li><Link to="/avvik">Avvik</Link></li>
                        <li><Link to="/om-movai">Om</Link></li>
                    
                    </ul>
                )}
            </nav>
        </header>
    );
};

export default Header;
