import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './../../auth/AuthContext';

const BackendHeader = () => {
	const { token, logout } = useAuth();
	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate('/');
	};
	useEffect(() => {
        const interval = setInterval(() => {
            const element = document.getElementById('some-element-id');
            if (element) {
                element.textContent = 'Updated content';
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);


	return (
		<header className="backend-header">
			<Link to="/"><h1 className="header-logo-text">movai</h1></Link>
			<p className="backend-header-clock"></p>
			<nav>
				{token ? (
					<button className="btn-login" onClick={handleLogout}>Logg ut</button>
				) : (
					<Link to="/login">Logg inn</Link>
				)}
			</nav>
		</header>
	);
};

export default BackendHeader;
