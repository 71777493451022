import React from 'react';
import Header from '../components/common/Header';
import DepartureBoardForm from '../components/departureBoard/CreateDepartureBoard';

const DepartureBoardPage = () => {

	return (
		<div>
			<Header />
			<div className="user-profile-page"> 
				<div className="db-content">
					<h1>Avgangsskilt</h1>
					<p>Lag avgangsskilt for alle holdeplasser i hele Norge.</p>
					<DepartureBoardForm />
				</div>
			</div>
			</div>
		);
};

export default DepartureBoardPage;
