import React from 'react';
import { isAndroid, isIOS } from './../../utils/deviceUtils';


const HertzPopupContent = ({ feature }) => {
    const { rental_uri_android, rental_uri_ios, rental_uri_web } = feature.properties;
    let availableVehiclesText = '';
    const availableCars = JSON.parse(feature.properties.available_vehicles);
    // print detailed array detials of available cars


    // Determine which logo to show based on the user's device
  const getAppLogo = () => {
    if (isAndroid()) {
      return (
        <a href={rental_uri_android}>
          <img src="./images/android-logo.png" alt="Android logo" />
        </a>
      );
    } else if (isIOS()) {
      return (
        <a href={rental_uri_ios}>
          <img src="./images/apple-logo1.png" alt="iOS logo" />
        </a>
      );
    } else {
      // Fallback or default content for non-Android and non-iOS devices
      return <div></div>;
    }
  };

    if (availableCars.length !== 0) {
        availableVehiclesText = availableCars.map(car => {
            const propulsionType = car.propulsion_type === "electric" ? "Elektrisk" : car.propulsion_type === "combustion" ? "Bensin" : "";
            return (
                <div className='cr-popup-car' key={car.id}>
                    <p>{car.name}</p>
                    <p className='cr-popup-meta'>{propulsionType}</p>
                </div>
            );
        });
    } else {
        availableVehiclesText = <div className="cr-popup-car"><p>Ingen ledige biler</p></div>;
    }

    return (
        <div className="cr-popup-content">
            <div className="cr-popup-header">
                <h3>{feature.properties.operator}</h3>
                <p className="cr-popup-meta">Bildeling</p>
            </div>
            <div className="cr-popup-body">
                <p><b>Tilgjengelige kjøretøy</b></p>
                {availableVehiclesText}
            </div>

            <div className="pop-up-logos">
            {getAppLogo()}
            { rental_uri_web &&
                <a href={rental_uri_web}>    
                    <img src="./images/desktop-icon.png" alt="desktop icon" />
                </a>
                }
            </div>
        </div>
    );
};

export default HertzPopupContent;
