import React, { useEffect, useState } from 'react';
import LineDetailsComponent from './LineDetailsChild';
import useIsMobile from './../../hooks/useIsMobile';

async function fetchLineDetails(lineId) {
    const response = await fetch('https://api.entur.io/journey-planner/v3/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'ET-Client-Name': 'movai-alpha-node'
        },
        body: JSON.stringify({
            query: `
        {
            serviceJourney(
              id: "${lineId}") 
             {
                privateCode
                transportMode
                transportSubmode
                estimatedCalls {
                  quay {
                    name
                    id
                    stopType
                    publicCode
                    situations {
                      advice {
                        language
                        value
                      }
                      description {
                        language
                        value
                      }
                      infoLinks {
                        label
                        uri
                      }
                      severity
                      summary {
                        language
                        value
                      }
                    }
                    longitude
                    latitude
                  }
                  expectedArrivalTime
                  notices {
                    id
                    publicCode
                    text
                  }
                  forAlighting
                  forBoarding
                  expectedDepartureTime
                }
                publicCode
                wheelchairAccessible
                operator {
                  name
                  id
                  phone
                  url
                }
                line {
                    id
                    name
                    publicCode
                  }
              }
            }
        `,
        }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.data;
}

const TravelPlannerLineDetails = ({ lineId }) => {
    const [lineDetails, setLineDetails] = useState(null);
    const isMobile = useIsMobile();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
			
            try {
                const data = await fetchLineDetails(lineId);
                setLineDetails(data);
                if (isMobile) setModalOpen(true);
            } catch (error) {
                console.error('Error fetching line details:', error);
            }
        };

        fetchData();
    }, [lineId]);

    if (!lineDetails) {
        return <div>Laster</div>;
    }

    return (
        <>
            <LineDetailsComponent lineDetails={lineDetails} />
        </>
    );
};

export default TravelPlannerLineDetails;
