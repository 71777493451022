import React from 'react';
import CarStationPopup from './DefaultCarPopup';
import HertzPopupContent from './HertzPopupContent';

const CarPopup = ({ feature }) => {
    const operator = feature.properties.operator;

    // Determine which component to render based on the operator
    switch (operator) {
        case "Hertz":
            return <HertzPopupContent feature={feature} />;
        case "Hyre":
            return <HertzPopupContent feature={feature} />;
        case "Otto":
            return <HertzPopupContent feature={feature} />;
        default:
            return <CarStationPopup feature={feature} />;
    }
};

export default CarPopup;