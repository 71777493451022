// src/components/common/FavStopStar.js
import React, { useState, useEffect } from 'react';
import { useAuth } from './../../auth/AuthContext';

const FavStopStar = ({ stopName, quayId }) => {
    const { token } = useAuth();
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        if (token) {
            const fetchFavorites = async () => {
                const response = await fetch('https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/favorites', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const favorites = await response.json();
                    setIsFavorite(favorites.some(fav => fav.quay_id === quayId));
                }
            };
            fetchFavorites();
        } else {
            const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
            setIsFavorite(storedFavorites.some(fav => fav.quayId === quayId));
        }
    }, [token, quayId]);

    const toggleFavorite = async () => {
        if (token) {
            const url = 'https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/favorites';
            const method = isFavorite ? 'DELETE' : 'POST';
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ stop_name: stopName, quay_id: quayId })
            });

            if (response.ok) {
                setIsFavorite(!isFavorite);
            }
        } else {
            let storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
            if (isFavorite) {
                storedFavorites = storedFavorites.filter(fav => fav.quayId !== quayId);
            } else {
                storedFavorites.push({ stopName, quayId });
            }
            localStorage.setItem('favorites', JSON.stringify(storedFavorites));
            setIsFavorite(!isFavorite);
        }
    };

    return (
        <button onClick={toggleFavorite}>
            {isFavorite ? '★' : '☆'}
        </button>
    );
};

export default FavStopStar;
