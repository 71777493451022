import React from 'react';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import NamespaceTranslator from './../common/NamespaceTranslator';

const SituationItem = ({ situation }) => {

  let startTime = "";
  let endTime = "";
  if (situation.validityPeriod.startTime) {
    startTime = format(parseISO(situation.validityPeriod.startTime), 'dd. MMMM HH:mm', { locale: nb });

  }
  if (situation.validityPeriod.endTime) {
    endTime = format(parseISO(situation.validityPeriod.endTime), 'dd. MMMM yyyy HH:mm', { locale: nb });

  }
 
  let creationTime = format(parseISO(situation.creationTime), 'dd. MMMM HH:mm', { locale: nb });
  
  return (
    <div key={situation.id} id={situation.id} className="situation-item">
		<div className="situation-header">
			
			<div className="situation-header-meta">
				<div className={`situation-severity situation-severity-` + situation.severity} title={situation.severity}></div>
				<p><b>{<NamespaceTranslator namespace={situation.participant} />}</b></p>
				<p>Gylding fra <b>{startTime}</b> til <b>{endTime}</b></p>
			</div>
			
        
		</div>
    <div className="situation-body">

		<div className="situation-header-title">
			<h2>{situation.title}</h2>
			<h3>{situation.summary[0].value}</h3>
		</div>
	
			<p>{situation.description[0].value}</p>
			<p>{situation.advice[0].value}</p>
      	<div className="situation-affects">
     
      {situation.affects[0].__typename === "AffectedStopPlace" && <h4>Berørte holdeplasser</h4>}
      <p>
      {situation.affects[0].__typename === "AffectedStopPlace" && situation.affects.map(affect => (
        
        affect.quay && affect.quay.name + " " 
        
          ))}</p>

          {situation.affects[0].__typename === "AffectedLine" && <h4>Berørte linjer</h4>}
                <p>
          {situation.affects[0].__typename === "AffectedLine" && situation.affects.map(affect => (
          
                  affect.line && affect.line.name + " " 
                
      
        ))}
      </p>
      </div>
      
      <p>Opprettet: {creationTime}</p>
      </div>
 
    </div>
  );
};

export default SituationItem;
