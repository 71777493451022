import React, { useEffect, useState } from 'react';
import { useAuth } from './../auth/AuthContext';
import Header from './../components/common/Header';
import FavoriteStops from './../components/common/FavoriteStops';
import './../styles/UserProfile.css';

const ProfilePage = () => {
    const { token, deleteUser } = useAuth();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            const response = await fetch('https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setProfile(data);
            } else {
                console.error('Failed to fetch profile');
            }
        };

        fetchProfile();
    }, [token]);

    if (!profile) return <div>Loading...</div>;

    return (
        <div>
            <Header />
            <div className="user-profile-page">
                <h1>Din brukerkonto</h1>
                <p id="travel-planner-body-text" className="tp-ai-description">
                    Her du se dine favoritt holdeplasser, lagrede reiser eller konfiguerer startskjerm når movai starter.<br/>
                </p>
                <div className="travel-planner-main">
                    <div className="profile-container">
                        <h1>Profile</h1>
                        <p>Username: {profile.username}</p>
                        <p>Email: {profile.email}</p>
                        <button onClick={deleteUser}>Slett brukerkonto</button>
                        <FavoriteStops />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
