import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import './../styles/Login.css';

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, email })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }

            navigate('/login');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            <Header />
            <div className="user-profile-page">
                <h1>Opprett brukerkonto</h1>
                <div className="login-container">
                    <p>Med en gratis brukerkonto hos Movai kan du lagre dine favoritt holdeplasser, lagre reisesøk og konfigurer hvordan din Movai applikajson skal se ut.</p>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Brukernavn" required />
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Passord" required />
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-post" required />
                        {error && <div className="error">{error}</div>}
                        <button type="submit">Opprett brukerkonto</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
