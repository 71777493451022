import React from 'react';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import TravelPlannerStopDetails from './TravelPlannerStopDetails';
import TravelPlannerLineDetails from './TravelPlannerLineDetails';
import TextToSpeechButton from '../map/TextToSpeechButton';
import TravelLeg from './tripdetails/TravelLeg';
import SaveToPDFButton from './savetopdf/SaveToPDFButton.js';
import SaveToAudio from './audio/SaveToAudio';
import GenerateShareLink from './savetravel/GenerateShareLink';

const TripDetailsMobile = ({ trip }) => {
    const [selectedDetail, setSelectedDetail] = useState({ type: null, id: null });
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    let detailsDivCounter = 1;

    const toggleDetailsVisibility = () => {
        setIsDetailsVisible(prevState => !prevState);
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} min`;
    };

    const handleStopClick = (nsrId) => {
        setSelectedDetail({ type: 'stop', id: nsrId });
    };

    const handleLineClick = (lineId) => {
        setSelectedDetail({ type: 'line', id: lineId });
    };

    const differenceInMinutes = (date1, date2) => {
        return Math.abs(date1 - date2) / 60000;
    };

    const translateMode = (mode) => {
        switch (mode) {
            case 'bus':
                return 'buss';
            case 'tram':
                return 'trikk';
            case 'metro':
                return 't-bane';
            case 'train':
                return 'tog';
            case 'boat':
                return 'båt';
            case 'foot':
                return 'gå';
            case 'air':
                return 'fly';
            case 'coach':
                return 'buss';
            case 'rail':
                return 'tog';
            default:
                return mode;
        }
    };

    const formatTime = (time) => { 
        return format(parseISO(time), 'p', { locale: nb });
    };

    const formatDuration2 = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        if (minutes > 60) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} time og ${remainingMinutes} minutter`;
        } else if (minutes === 60) {
            return '1 time';
        } else if (minutes <= 1) {
            return 'ett minutt';
        } else {
        return `${minutes} minutter`;
    }

    };

    const ttsText = `Reisen starter fra ${trip.legs[0].fromPlace.name} klokken ${format(parseISO(trip.expectedStartTime), 'p', { locale: nb })} og avsluttes ved ${trip.legs[trip.legs.length - 1].toPlace.name} klokken ${format(parseISO(trip.legs[trip.legs.length - 1].expectedEndTime), 'p', { locale: nb })}`;


    const generateDetailedText = () => {
        const startPlace = trip.legs[0].fromPlace.name;
        const endPlace = trip.legs[trip.legs.length - 1].toPlace.name;
        const travelDate = format(parseISO(trip.expectedStartTime), 'dd. MMMM yyyy', { locale: nb });
        const startTime = format(parseISO(trip.expectedStartTime), 'HH:mm', { locale: nb });
        const endTime = format(parseISO(trip.legs[trip.legs.length - 1].expectedEndTime), 'HH:mm', { locale: nb });
        const numberOfTransfers = trip.legs.length - 1;
        const duration = formatDuration(differenceInMinutes(parseISO(trip.legs[trip.legs.length - 1].expectedEndTime), parseISO(trip.expectedStartTime)) * 60);

        const legsDetails = trip.legs.map((leg, index) => {
            var modeDetails = '';
            if (leg.mode === 'foot') {
                modeDetails =  `gå fra ${leg.fromPlace.name} klokken ${formatTime(leg.expectedStartTime)} til ${leg.toPlace.name}. Beregnet gåtid er ${formatDuration2(leg.duration)}`;
            } else {
                modeDetails =   `ta ${translateMode(leg.mode)} ${translateMode(leg.line.publicCode)} ${translateMode(leg.line.name)} fra ${leg.fromPlace.name} klokken ${formatTime(leg.expectedStartTime)} til ${leg.toPlace.name} med ankomst klokken ${formatTime(leg.expectedEndTime)}`;
            }
            const legDetail = `${modeDetails}`;
            return index === 0 ? legDetail : `deretter ${legDetail}`;
        }).join(', ');

        return `Reise fra ${startPlace} til ${endPlace} den ${travelDate}. Reisen starter ${startTime} og ankomsttiden er ${endTime}. Reisen har ${numberOfTransfers} overganger og varer ${duration}. Reiseplan: ${legsDetails}.`;
    };
const detailedText = generateDetailedText();

    const lastLeg = trip.legs[trip.legs.length - 1];

    return (
        <div className="trip-detail travelplaner-row">
            <div className="travelplaner-meta-container">
                <div className="travelplaner-from-container">
                    <div className="travelplaner-from">
                        <p className="travelplaner-from-label">Fra </p>
                        <p className="travelplaner-from-name">{trip.legs[0].fromPlace.name}</p>
                    </div>

                    <div className="travelplaner-from-time">
                        <p className="travelplaner-start-label">Start </p>
                        <p className="travelplaner-start">{format(parseISO(trip.expectedStartTime), 'p', { locale: nb })}</p>
                    </div>
                </div>

                <div className="travelplaner-to-container">
                    <div className="travelplaner-to">
                        <p className="travelplaner-to-label">Til </p>
                        <p className="travelplaner-to-name">{lastLeg.toPlace.name}</p>
                    </div>
                    <div className="travelplaner-to-time">
                        <p className="travelplaner-end-label">Slutt </p>
                        <p className="travelplaner-end">{format(parseISO(lastLeg.expectedEndTime), 'p', { locale: nb })}</p>
                    </div>

                </div>
            </div>

            <div className="travelplaner-all-container">
            <div className="travelplaner-details-button-container">

                

                        
                
                </div>
                <div className="travelplaner-legs-container">
                    {trip.legs.map((leg, index) => (
                        <TravelLeg
                            key={index}
                            leg={leg}
                            isFirst={index === 0}
                            isLast={index === trip.legs.length - 1}
                            onlyLeg={trip.legs.length === 1}
                            detailed={false}
                            handleStopClick={handleStopClick}
                            handleLineClick={handleLineClick}
                            formatDuration={formatDuration}
                        />
                    ))}
                </div>

                

                {isDetailsVisible && (
                    <div id={`travel-details-${detailsDivCounter}`} className="travelplaner-details-container">
                        <div className="travelplaner-details-button-container">
                        <SaveToAudio
                            text={detailedText}
                            fromPlace={trip.legs[0].fromPlace.name}
                            toPlace={lastLeg.toPlace.name}
                            travelDate={trip.expectedStartTime}
                        />
                        <SaveToPDFButton trip={trip} />

            
                       

        </div>
                     {/*   <div id="meta-details" className="travelplaner-meta-details">
                            {selectedDetail.type === 'stop' && <TravelPlannerStopDetails nsrId={selectedDetail.id} />}
                            {selectedDetail.type === 'line' && <TravelPlannerLineDetails lineId={selectedDetail.id} />}
                        </div> */}
                        <div className="travelplaner-trip-details">
                            {trip.legs.map((leg, index) => (
                                <TravelLeg
                                    key={index}
                                    leg={leg}
                                    isFirst={index === 0}
                                    isLast={index === trip.legs.length - 1}
                                    onlyLeg={trip.legs.length === 1}
                                    detailed={true}
                                    handleStopClick={handleStopClick}
                                    handleLineClick={handleLineClick}
                                    formatDuration={formatDuration}
                                />
                            ))}
                            
                        </div>

                        
                        
                    </div>
                )}
                
  
                <div className="travelplaner-details-button-container">
                
                
                <TextToSpeechButton
                            text={ttsText}
                            fromPlace={trip.legs[0].fromPlace.name}
                            toPlace={lastLeg.toPlace.name}
                            travelDate={trip.expectedStartTime}
                        />
                         <GenerateShareLink text={ttsText} fromPlace={trip.legs[0].fromPlace.name} toPlace={lastLeg.toPlace.name} travelDate={trip.expectedStartTime} />
                        <button aria-label="Vis eller skjul detaljer" className="travelplaner-btn" onClick={toggleDetailsVisibility} tabIndex={0}>
                        {!isDetailsVisible ? <p>Åpne detaljer</p> : <p>Lukk detaljer</p>}
                        <img className="travelplaner-btn-icon" src="./images/travelplanner/details-white.png" alt="Åpne detaljer" />
                </button>
                </div>
        </div>
    </div>
    );
};

export default TripDetailsMobile;
