import React, { useState } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import ShareLinkModal from '../../common/modals/ShareLinkModal';

const GenerateShareLink = ({ fromPlace, toPlace, travelDate }) => {
    const [shareLink, setShareLink] = useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const GenerateLink = async () => {
        const formattedDate = format(parseISO(travelDate), 'dd-MM-yy-HH-mm', { locale: nb });
        const travelDetails = { 'fromPlace': fromPlace, 'toPlace': toPlace, 'formattedDate': formattedDate };

        try {
            const response = await axios.post('https://rosander.no/akkuratda/backend/generate_short_url.php', {
                tripData: JSON.stringify(travelDetails)
            });
            setShareLink(response.data.shortUrl);
            setModalVisible(true);
        } catch (error) {
            console.error('Error generating link:', error);
        }
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <button onClick={GenerateLink} aria-label="Del reise" className="travelplaner-btn">
                <p>Del reise</p>
                <img className="travelplaner-btn-icon" src="./images/travelplanner/share-white.png" alt="Del reise" />
            </button>
            {modalVisible && <ShareLinkModal shareLink={shareLink} onClose={handleCloseModal} />}
        </>
    );
};

export default GenerateShareLink;
