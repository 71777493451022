import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import ModeTranslator from './../common/ModeTranslator';

mapboxgl.accessToken = 'pk.eyJ1Ijoicm9zYW5kZXIiLCJhIjoiY2x1OWZuNmhiMDl1dDJsbW01aGc3Ynh3MyJ9.3Ip3cN8u5ysppMZ2cQlxUg';

const LineDetailsMap = ({ lineId }) => {
    const [lineData, setLineData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchLineData = async () => {
            if (!lineId) return;

            const query = `{
                line(id: "${lineId}") {
                    bikesAllowed
                    publicCode
                    journeyPatterns {
                        pointsOnLink {
                            points
                        }
                    }
                    name
                    transportMode
                    transportSubmode
                }
            }`;

            try {
                const response = await fetch('https://api.entur.io/journey-planner/v3/graphql', {
                    method: 'POST',
                    headers: {
                        'ET-Client-Name': 'movai-departure-node-04', // Update this with your client name
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query }),
                });

                const apiResponse = await response.json();
                if (apiResponse.data && apiResponse.data.line) {
                    setLineData(apiResponse.data.line);
                } else {
                    setError("No data returned from API.");
                }
            } catch (error) {
                setError(`Error fetching line data: ${error.toString()}`);
            }
        };

        fetchLineData();
    }, [lineId]);

    useEffect(() => {
        if (lineData && lineData.journeyPatterns.length > 0) {
            const coordinates = decodePolyline(lineData.journeyPatterns[0].pointsOnLink.points);

            const map = new mapboxgl.Map({
                container: 'map2', // Container ID
                style: "mapbox://styles/rosander/clz027a8400fr01nv8bdg3bsj",
                center: [10.165665, 59.749976], // This will be overridden by fitBounds below
                zoom: 9 // This will be overridden by fitBounds below
            });

            map.on('load', function () {
                map.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: coordinates
                        }
                    }
                });

                map.addLayer({
                    id: 'route',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': '#4f5259',
                        'line-width': 4
                    }
                });

                // Calculate bounds from the coordinates
                const bounds = coordinates.reduce(function (bounds, coord) {
                    return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

                map.fitBounds(bounds, {
                    padding: 20
                });
            });
        }
    }, [lineData]);

    function decodePolyline(encoded) {
        let points = [];
        let index = 0, len = encoded.length;
        let lat = 0, lng = 0;

        while (index < len) {
            let b, shift = 0, result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            let dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lat += dlat;

            shift = 0;
            result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            let dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            points.push([lng * 1e-5, lat * 1e-5]);
        }

        return points;
    }




    return (
        <div className="line-details-map-container">
            
            <div id="map2" style={{ width: '400px', height: '300px' }}></div>
            {error && <p>{error}</p>}
        </div>
    );
};

export default LineDetailsMap;
