import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import TravelPlannerPage from './pages/TravelPlannerPage';
import MapPage from './pages/MapPage';
import DepartureBoardPage from './pages/DepartureBoardPage';
import DepartureBoard from './components/departureBoard/DepartureBoard';
import { AuthProvider } from './auth/AuthContext';
import BackendDashboard from './components/dashboard/BackendDashboard';
import ProtectedRoute from './components/login/ProtectedRoute';
import { PopupProvider } from './context/PopupContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import SituationMessagesPage from './pages/SituationPage';
import LoginPage from './pages/Login';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ProfilePage from './pages/ProfilePage';
import RedirectHandler from './components/travelplanner/savetravel/RedirectHandler';
import ConsentBanner from './components/common/consent/ConsentBanner';

const App = () => {
  const handleConsentChange = (consent) => {
    if (consent) {
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    } else {
      window.gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
    }
  };

  useEffect(() => {
    const consent = localStorage.getItem('user_consent');
    if (consent !== null) {
      handleConsentChange(consent === 'true');
    }
  }, []);

  return (
    <Router>
      <AuthProvider>
        <PopupProvider>
          <ConsentBanner onConsentChange={handleConsentChange} />
          <Routes>
            <Route path="/" element={<TravelPlannerPage />} />
            <Route path="/kart" element={<MapPage />} />
            <Route path="/avgangsskilt" element={<DepartureBoardPage />} />
            <Route path="/om-movai" element={<AboutPage />} />
            <Route path="/avganger" element={<DepartureBoard />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><BackendDashboard /></ProtectedRoute>} />
            <Route path="/avvik" element={<SituationMessagesPage />} />
            <Route path="/r/:shortCode" element={<RedirectHandler />} />
          </Routes>
        </PopupProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
