import React from 'react';
import { parseISO, format } from 'date-fns';
import { nb } from 'date-fns/locale';

const DetailedGeneralLeg = ({ leg, isFirst, isLast, handleStopClick, handleLineClick, formatDuration }) => {
  return (
    <div className="travelplaner-trip-details-item">
      <div className="travelplaner-details-time-container">
        <p className="travelplaner-details-left-time">{format(parseISO(leg.expectedStartTime), 'p', { locale: nb })}</p>
        <p className="travelplaner-details-right-time">{format(parseISO(leg.expectedEndTime), 'p', { locale: nb })}</p>
      </div>
      
      <div className="travelplaner-trip-details-icon-container2">

		<div className="travelplaner-hidden-details-bar-container">
			<span className={`travelplaner-hidden-details-bar ${leg.mode}-color`}></span>
		</div>

        <div className="travelplaner-trip-details-layout-container">

            <div className="travelplaner-details-mode-container travelplaner-details-mode-stop-name-container" onClick={() => handleStopClick(leg.fromPlace.quay.id)}>
				<div className="tripdetails-stop-icon">
					<img className="travelplaner-trip-details-icon" src={`./images/modes/stop-white.png`} alt="Holdeplass ikon" />
				</div>
				<div className="travelplaner-hidden-details-line-name-container">
					<p className="travelplaner-trip-details-text-quay">{leg.fromPlace.name}</p>
					<p className="travelplaner-hidden-details-leg-duration">Avreise</p>
				</div>
          </div>
      <div className="travelplaner-hidden-details-mode-container" onClick={() => handleLineClick(leg.serviceJourney.id)}>
        <div className="travelplaner-hidden-line-name-container">
          <div className={`travelplaner-hidden-details-line-number travelplaner-details-line-number-${leg.mode}`}>
            <img className="travelplaner-trip-details-icon" src={`./images/modes/${leg.mode}-white.png`} alt={leg.mode} />
            <p>{leg.line.publicCode}</p>
          </div>
          <div className="travelplaner-hidden-details-line-name-container">
            <p className="travelplaner-hidden-details-line-name">{leg.line.name}</p>
            <p className="travelplaner-hidden-details-leg-duration">Reisetid: {formatDuration(leg.duration)}</p>
          </div>
        </div>
      </div>

      {/* Checking if there's a toPlace for the ending stop of the leg */}
      {leg.toPlace && (
        <div className="travelplaner-details-mode-container travelplaner-details-mode-stop-name-container" onClick={() => handleStopClick(leg.toPlace.quay.id, leg.toPlace)}>
          
          <div className="tripdetails-stop-icon">
            <img className="travelplaner-trip-details-icon" src={`./images/modes/stop-white.png`} alt="Holdeplass ikon" />
          </div>
          <div className="travelplaner-hidden-details-line-name-container">
          <p className="travelplaner-trip-details-text-quay">{leg.toPlace.name}</p>
          <p className="travelplaner-hidden-details-leg-duration">Ankomst</p>
        </div>
      </div>
      )}</div>
      </div>
    </div>
  );
};

export default DetailedGeneralLeg;
