import React from 'react';
import axios from 'axios';

// TextToSpeechButton component
const TextToSpeechButton = ({ text }) => {
    const speakText = async () => {
    const filename = `temp-audio`;
        
        try {
            const response = await axios.post(
                'https://movai.no/savetoaudio-proxy.php',
                { text, filename },
                { responseType: 'blob' }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const audio = new Audio(url);
            audio.play();
        } catch (error) {
            console.error('Error generating MP3:', error);
        }
    };

    return (
        <button onClick={speakText} aria-label="Les høyt" className="travelplaner-btn">
            <p>Spill av</p>
            <img className="travelplaner-btn-icon" src="./images/travelplanner/speaker-white.png" alt="Høyttaler ikon for avspilling" />
        </button>
    );
};

export default TextToSpeechButton;
