import React, { useState } from 'react';
import Header from '../components/common/Header';
import FilterForm from './../components/situations/FilterForm';
import SituationList from './../components/situations/SituationList';
import './../styles/Situations.css';

const SituationMessagesPage = () => {
  const [filters, setFilters] = useState({ quay: '', line: '', participant: '', date: null });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <Header />
      <div className="situation-messages-page">
        
        <h1>Avviksmeldinger</h1>
        <p></p>
        <FilterForm onFilterChange={handleFilterChange} />
        <SituationList filters={filters} />
      </div>
    </div>
  );
};

export default SituationMessagesPage;
