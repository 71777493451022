import React, { useState, useEffect } from 'react';
import { useAuth } from './../../auth/AuthContext';
import './../../styles/common/FavoriteStops.css';

const FavoriteStops = () => {
    const { token } = useAuth();
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        const fetchFavorites = async () => {
            const response = await fetch('https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/favorites', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setFavorites(data);
            }
        };

        fetchFavorites();
    }, [token]);

    const removeFavorite = async (quayId) => {
        const response = await fetch('https://rosander.no/akkuratda/backend/wp-json/movai-wp/v1/favorites', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ quay_id: quayId })
        });

        if (response.ok) {
            setFavorites(favorites.filter(fav => fav.quay_id !== quayId));
        }
    };

    return (
        <div>
            <h2>Dine favoritt holdeplasser</h2>
            <div className="favorite-stops-container">
                {favorites.map(fav => (
                    <div className="favorite-stop-child" key={fav.quay_id}>
                        <div className="favorite-stop-list-icon"><img src="./images/icons/stop.png" /></div>{fav.stop_name} <button onClick={() => removeFavorite(fav.quay_id)}>Remove</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FavoriteStops;
