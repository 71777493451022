import React from 'react';

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationGroup = () => {
    let start = Math.max(currentPage - 2, 1);
    let end = Math.min(start + 4, totalPages);

    if (totalPages >= 5 && end === totalPages) {
      start = totalPages - 4;
    }

    return Array.from({ length: (end - start + 1) }, (_, i) => start + i);
  };

  return (
    <div className="pagination">
      <span>Page {currentPage} of {totalPages}</span>
      <button
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        className="page-item"
      >
        Første
      </button>
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="page-item"
        >
          Forrige
        </button>
      )}
      {getPaginationGroup().map(page => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          disabled={currentPage === page}
          className={`page-item ${currentPage === page ? 'active' : ''}`}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="page-item"
        >
          Neste
        </button>
      )}
      <button
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages}
        className="page-item"
      >
        Siste
      </button>
    </div>
  );
};

export default Pagination;
