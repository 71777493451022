import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const RedirectHandler = () => {
    const { shortCode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTripData = async () => {
            try {
                const response = await axios.get(`https://rosander.no/akkuratda/backend/get_trip_data.php?shortCode=${shortCode}`);
                const tripData = response.data.long_url;
                const parsedData = JSON.parse(tripData);
                const tripDetails = JSON.parse(parsedData.tripData);
                const queryParams = new URLSearchParams({
                    fromPlace: tripDetails.fromPlace,
                    toPlace: tripDetails.toPlace,
                    formattedDate: tripDetails.formattedDate
                }).toString();
                navigate(`/?${queryParams}`, { replace: true });
            } catch (error) {
                console.error('Error fetching trip data:', error);
                navigate('/', { replace: true });
            }
        };

        fetchTripData();
    }, [shortCode, navigate]);

    return <div>Videresender...</div>;
};

export default RedirectHandler;