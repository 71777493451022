import React, { useState, useMemo } from 'react';
import QuaySelector from './QuaySelector';
import LineSelector from './LineSelector';
import NamespaceTranslator from './../common/NamespaceTranslator';
import situationsData from './../../cr-data/situations.json';

const FilterForm = ({ onFilterChange }) => {
    const [quay, setQuay] = useState('');
    const [line, setLine] = useState('');
    const [participant, setParticipant] = useState('');
    const [date, setDate] = useState('');

    const operators = useMemo(() => {
      const operatorSet = new Set();
      situationsData.forEach(situation => operatorSet.add(situation.participant));
      return Array.from(operatorSet);
    }, []);

    const extractQuays = () => {
      const quays = new Set();
      situationsData.forEach(situation => {
        situation.affects.forEach(affect => {
          if (affect.quay) quays.add(affect.quay);
        });
      });
      return Array.from(quays);
    };

    const extractLines = () => {
      const lineSet = new Set();
      situationsData.forEach(situation => {
        situation.affects.forEach(affect => {
          if (affect.line && affect.line.id) {
            lineSet.add(JSON.stringify(affect.line)); // Serialize to string to ensure uniqueness
          }
        });
      });
      return Array.from(lineSet).map(line => JSON.parse(line));
    };

    const handleQuaySelect = (selectedQuay) => {
      setQuay(selectedQuay.name);
      onFilterChange({ quay: selectedQuay.name, line, participant, date });
    };

    const handleLineSelect = (selectedLine) => {
      setLine(selectedLine.name);
      onFilterChange({ quay, line: selectedLine.name, participant, date });
    };


    const clearFilters = () => {
      setQuay('');
      setLine('');
      setParticipant('');
      setDate(null);
      onFilterChange({ quay: '', line: '', participant: '', date: null });
    };

    const handleOperatorChange = (e) => {
      setParticipant(e.target.value);
      onFilterChange({ quay, line, participant: e.target.value, date });
    };

    return (
      <form onSubmit={(e) => e.preventDefault()} className="situation-messages-filter-container">
       {/* <div className="situation-messages-filter-group">
          <QuaySelector quays={extractQuays()} onQuaySelect={handleQuaySelect} selectedQuay={quay} />
          <LineSelector lines={extractLines()} onLineSelect={handleLineSelect} selectedLine={line} />
        </div> */}
        <div className="situation-messages-filter-group">
          <select value={participant} onChange={handleOperatorChange}>
            <option value="">Velg operatør</option>
            {operators.map(op => (
              <option key={op} value={op}>{<NamespaceTranslator namespace={op} />}</option>
            ))}
          </select>
          {/* <input type="date" value={date} onChange={(e) => setDate(e.target.value)} /> */}
        </div>
        <div className="situation-messages-filter-group">
          
          <button type="button" onClick={clearFilters}>Nullstil filter</button>
          <button type="submit">Søk</button>
        </div>
      </form>
    );
};

export default FilterForm;