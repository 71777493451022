import React, { useLayoutEffect, useRef } from "react";
import "./../../../styles/common/modals/ShareLinkModal.css";

const ShareLinkModal = ({ shareLink, onClose }) => {
    const modalRef = useRef();

    useLayoutEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            alert("Link copied to clipboard!");
        }).catch((err) => {
            console.error('Failed to copy the text to clipboard: ', err);
        });
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Del reise',
                    text: 'Sjekk ut denne reisen',
                    url: shareLink
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    useLayoutEffect(() => {
        // display the modal
        document.querySelector('.share-link-modal-overlay').style.display = 'flex';
        return () => {
            // clean up the modal display on unmount
            document.querySelector('.share-link-modal-overlay').style.display = 'none';
        };
    }, []);

    return (
        <div className="share-link-modal-overlay">
            <div className="share-link-modal" ref={modalRef}>
                <div className="share-link-modal-header">
                    <h1>Del reise</h1>
                    <button className="close-button" onClick={onClose}>X</button>
                </div>
                <div className="share-link-modal-content">
                    <p><b>Delingslenke:</b> <a href={shareLink} target="_blank" rel="noopener noreferrer">{shareLink}</a></p>

                </div>
                <div className="share-link-modal-btn-container">
                <button className="share-link-modal-btn" onClick={handleCopyLink}>Kopier lenke</button>
                <button className="share-link-modal-btn" onClick={handleShare}>Del reisesøk</button>
                </div>
                <div className="share-link-modal-footer">
                    <button onClick={onClose}>Lukk</button>
    
                </div>
            </div>
        </div>
    );
}

export default ShareLinkModal;
