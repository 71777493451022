import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../auth/AuthContext';
import Header from './../components/common/Header';
import './../styles/Login.css';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await login(username, password);
            navigate('/profile');
        } catch (error) {
            setError(error.message);
        }
    };

    return (        <div>
        <Header />
        <div className="user-profile-page">
            <h1>Logg inn</h1>
            <div className="login-container">
            <p id="travel-planner-body-text" className="tp-ai-description">
                Logg inn for å se dine favoritt holdeplasser, lagrede reiser eller konfiguerer startskjerm når movai starter.<br/>
            </p>
            <form onSubmit={handleSubmit} className="login-form-container">
                <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="E-post" required />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Passord" required />
                {error && <div className="error">{error}</div>}
                <button type="submit">Logg inn</button>
            </form>
        </div>
        </div>
    </div>
        
    );
};

export default LoginPage;
