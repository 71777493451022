// src/components/ConsentBanner.js
import React, { useState, useEffect } from 'react';
import './../../../styles/ConsentBanner.css';

const ConsentBanner = ({ onConsentChange }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('user_consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('user_consent', 'true');
    setIsVisible(false);
    onConsentChange(true);
  };

  const handleDecline = () => {
    localStorage.setItem('user_consent', 'false');
    setIsVisible(false);
    onConsentChange(false);
  };

  if (!isVisible) return null;

  return (
    <div className="consent-banner-container">
        <div className="consent-banner">
      <p>
        Movai ønsker å samle inn anonymiserte data for å føre statistikk over reisesøk. 
      </p>
      <a href="/privacy-policy" target="_blank">Les mer</a>
      </div>
        <div className="consent-banner-button-container">
      <button onClick={handleAccept}>Godta</button>
      <button onClick={handleDecline}>Avvis</button>
      </div>

    </div>
  );
};

export default ConsentBanner;
