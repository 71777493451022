import React, { useState, useEffect } from 'react';
import AudioRecorder from './audio/AudioRecorder';

const AnimatedPlaceholderInput = ({ setQuery }) => {
    const initialText = "Reise fra Kristiansand til Oslo i morgen";
    const replaceText = "dag kl. 15";
    const [placeholder, setPlaceholder] = useState(initialText);
    const [index, setIndex] = useState(initialText.length);
    const [step, setStep] = useState(0);
    const [isInteracted, setInteracted] = useState(false);
    const [localQuery, setLocalQuery] = useState('');

    useEffect(() => {
        let timeout;

        const startAnimation = () => {
            if (step === 0) {
                timeout = setTimeout(() => {
                    setStep(1);
                    setIndex(initialText.length - 1); // Start deleting "morgen"
                }, 2000);
            } else if (step === 1 && index >= initialText.length - 6) {
                setPlaceholder(initialText.slice(0, index));
                setIndex(index - 1);
                if (index === initialText.length - 6) {
                    setStep(2);
                    setIndex(0);
                }
            } else if (step === 2 && index <= replaceText.length) {
                setPlaceholder(initialText.slice(0, initialText.length - 6) + replaceText.slice(0, index));
                setIndex(index + 1);
                if (index === replaceText.length) {
                    setStep(3);
                }
            }
        };

        if (!isInteracted && step < 3) {
            timeout = setTimeout(startAnimation, 150);
        }

        return () => clearTimeout(timeout);
    }, [index, step, isInteracted]);

    const handleInputChange = (e) => {
        setLocalQuery(e.target.value);
        setInteracted(true);
    };

    const handleInputFocus = () => {
        setInteracted(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setQuery(localQuery); // Pass the query up to the parent component
    };

    const handleTranscription = (transcription) => {
        console.log('Transcription:', transcription);
        setLocalQuery(transcription);
        setQuery(transcription);
    };

    return (
        <div className='input-top'>
            <form onSubmit={handleSubmit}>
                <div className="travelplanner-input-form-group">
                <input
                    id="user-query"
                    type="text"
                    data-lpignore="true"
                    value={localQuery}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder={isInteracted ? '' : placeholder}
                />
                </div>
                <button type="submit">Søk</button>
            </form>
            
        </div>
    );
};

export default AnimatedPlaceholderInput;
