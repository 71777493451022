import React from 'react';
import Header from '../components/common/Header';
import './../styles/AboutPage.css';

const AboutPage = () => {
	return (
		<div> 
			<Header />
			<div className="about-page">
				<div className="about-page-content">
					<h1>Om movai</h1>

					<p><b>Movai er en plattform for smart og bærekraftig mobilitet.</b>
					<br/>Plattformen er for tiden i en offentlig alpha-fase og er planlagt lansert i en ferdig versjon høsten 2024.
					<br/>Movai visualiserer og tilgjengeliggjør sanntidsdata fra kollektivtransport, bildeling, mikromobilitet, elbil-ladere og mer.</p>
					<p>Målet med plattformen er å øke tilgjengeligheten og fremme miljøvennlige transportalternativer.</p>
					<p>Plattformen er enkel å bruke og har flere funksjoner for økt tilgjengelighet. Reisesøkmotoren består av flere AI-modeller som bistår ved reisesøk og andre reise- og mobilitetsrelaterte tjenester.</p>
					<p>Movai er utviklet og drives av <a className="about-link" href="https://rosander.no" title='Rosander.no' aria-label='Link til Rosander.no'>Rosander.no</a>.</p>
					
					<h2 id="test">Infrastruktur</h2>
					<p><b>Movai er bygget på en moderne infrastruktur som benytter datasentre drevet av fornybar energi.</b><br/>Hosting i AWS-region Stockholm sikrer lav responstid fra et moderne og bærekraftig datasenter.</p>
					<p>Plattformen består av en React frontend som kommuniserer med flere noder som sammen danner den fullstendige funksjonaliteten på plattformen. Kjernekomponenten i Movai er en multistage AI pipeline som tolker brukerinput og router forespørsler til de forskjellige nodene.</p>
					<p>Nodene er under kontinuerlig utvikling for å kunne håndtere større deler av plattformen og tilby nye spennende AI-assisterte grensesnitt.</p>

					<h2>Reiseplanlegger</h2>
					<p><b>Reiseplanleggeren gir rask og enkel tilgang til sanntidsinformasjon og oppdatert reisedata.</b></p>
					<p>En NER-modell med en multistage pipeline, trent av Movai på over en million dataoppføringer, tolker reisesøket slik at du kan gjøre reisesøk i fritekst. Modellen er rask og gir gode resultater, men er fortsatt i en tidlig fase.</p>
					<p>Den nåværende alpha modellen (alpha-03), er trent til å oppdage avreise- og ankomststed, tid og dato. Neste versjon vil inneholde filtrering og avanserte reisesøk, og er for tiden under evaluering før den implementeres i reisesøket.</p>

					<h2>Avgangsskilt</h2>
					<p><b>Avgangsskiltet gir sanntidsinformasjon om avganger fra holdeplasser i hele Norge.</b></p>
					<p>Avgangsskiltet kan brukes til å sjekke avganger i nærheten av deg eller benyttes som en informasjonsskjerm på større flater.<br />
					Skiltet oppdateres med sanntidsdata fra Entur 2 ganger i minuttet.</p>

					<h2>Tilgjengelighet</h2>
					<p><b>A11y-noden er en node med flere pipelines for å håndtere en rekke tilgjengelighetsfunksjoner.</b></p>
					<p>Ved hjelp av Whisper-modellen fra <a className="about-link" href="https://ai.nb.no/models/" title='ai.nb.no/models/' aria-label='NB AI-lab'>NB AI-lab</a> håndterer noden tale til tekst.<br/>
					Noden er for tiden under trening for å bedre kunne ignorere bakgrunnsstøy.</p>
					<p>Reisesøkresultater blir oversatt fra tekst til tale slik at brukere kan lagre reisesøk som lydfiler på sin egen enhet.</p>
					<p>Samme pipeline benyttes for å generere høytlesing av resultater i reiseplanleggeren.</p>

					<h2>Kontakt</h2>
					<p><b>Movai-plattformen er foreløpig i en offentlig alpha-fase hvor AI-modellene er under evaluering og konstant forbedring.</b></p>
					<p>Har du innspill eller tips til funksjonalitet som kan implementeres på plattformen, kan du sende en e-post til <a className="about-link" href="mailto:utvikler@movai.no">utvikler@movai.no</a>.</p>

					<h2>Personvern</h2>
					<p><b>Movai tar personvern på alvor. Vi lagrer ingen personlig informasjon om deg og deler heller ikke data om deg med tredjeparter.</b></p>
					<p>Ved bruk av kartet lagres en liste over tilgjengelige operatører i din nettleser slik at tjenesten husker dine filtervalg.</p>

					<h2>Datakilder</h2>
					<p>Movai samler inn, formaterer og mellomlagrer store mengder data fra en rekke åpne og private datakilder for å kunne tilby morgendagens plattform for mikromobilitet og AI-assistert reiseinformasjon.<br/>
					Her er noen av kildene Movai henter data fra: Entur, Yr, Hyre, MapBox, OpenStreetmap, m.fl.</p>

				</div>
			</div>
		</div>
	);
};

export default AboutPage;
