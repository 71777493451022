import React from 'react';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

const SaveToPDFButton = ({ trip }) => {
    const saveToPDF = async () => {
        const fromPlace = trip.legs[0].fromPlace.name;
        const toPlace = trip.legs[trip.legs.length - 1].toPlace.name;
        const travelDate = trip.expectedStartTime;
        const formattedDate = format(parseISO(travelDate), 'dd-MM-yy-HH-mm', { locale: nb });
        const filename = `reiseplan ${fromPlace} - ${toPlace} ${formattedDate}`;

        const response = await fetch('https://movai.no/savetopdf-proxy.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ trip, filename })
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else {
            console.error('Failed to generate PDF');
        }
    };

    return (
        <button onClick={saveToPDF} className="travelplaner-btn">
            <p>Lagre PDF</p>
            <img className="travelplaner-btn-icon" src="./images/travelplanner/pdf-white.png" alt="Lagre ikon for pdf" />
        </button>
    );
};

export default SaveToPDFButton;
