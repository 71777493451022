import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TripDetails from './TripDetails';
import SearchModifyForm from './SearchModifyForm';
import './../../styles/TravelPlanner.css';
import AnimatedPlaceholderInput from './SearchInputForm';
import LoadingIndicator from '../common/LoadingIndicator';
import TravelPlannerMessage from './TravelPlannerMessage';
import useIsMobile from '../common/useIsMobile';
import TripDetailsDesktop from './TripDetailsDesktop';
import TripDetailsMobile from './TripDetailsMobile';
import { format, parseISO } from 'date-fns';

const TravelPlanner = ({ initialSearchData }) => {
    const [query, setQuery] = useState('');
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState(initialSearchData || null);
    const [notice, setNotice] = useState('');
    const [nextPageCursor, setNextPageCursor] = useState(null);
    const [previousPageCursor, setPreviousPageCursor] = useState(null);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (initialSearchData) {
            const initialQuery = `Reise fra ${initialSearchData.fromPlace} til ${initialSearchData.toPlace} ${initialSearchData.travelDate}`;
            handleUserQuerySearch(initialQuery);
        }
    }, [initialSearchData]);

    const handleUserQuerySearch = async (query) => {
        setLoading(true);
        setNotice('');
        try {
            const response = await axios.post('https://movai.no/plan-journey.php', { query });
            updateSearchData(response.data.data);
        } catch (error) {
            console.error('Error fetching travel details:', error);
            setNotice('Feil ved henting av reisedetaljer. Vennligst prøv igjen.');
        } finally {
            setLoading(false);
        }
    };

    const handleModifyFormSearch = async (formData, pageCursor = null) => {
        setLoading(true);
        setNotice('');
        const fullFormData = {
            ...formData,
            ...(pageCursor && { pageCursor })
        };
        try {
            const response = await axios.post('https://movai.no/update_journey.php', fullFormData);
            updateSearchData(response.data.data);
        } catch (error) {
            console.error('Error fetching travel details:', error);
            setNotice('Feil ved henting av reisedetaljer. Vennligst prøv igjen.');
        } finally {
            setLoading(false);
        }
    };

    const updateSearchData = (data) => {
        if (!data.trip) {
            setNotice('Ingen reiser funnet, forsøk å endre søket ditt.');
            setTrips([]);
        } else if (data.trip.tripPatterns.length > 0) {
            setNotice('');
            setTrips(groupTripsByDate(data.trip.tripPatterns));
            setNextPageCursor(data.trip.nextPageCursor);
            setPreviousPageCursor(data.trip.previousPageCursor);
            setSearchData(data.searchData);
        } else {
            setNotice('Ingen reiser funnet, forsøk å endre søket ditt.');
            setTrips([]);
        }
    };

    const groupTripsByDate = (trips) => {
        return trips.reduce((groupedTrips, trip) => {
            const date = format(parseISO(trip.expectedStartTime), 'dd. MMMM yyyy');
            if (!groupedTrips[date]) {
                groupedTrips[date] = [];
            }
            groupedTrips[date].push(trip);
            return groupedTrips;
        }, {});
    };

    useEffect(() => {
        if (query) handleUserQuerySearch(query);
    }, [query]);

    const formData = searchData ? {
        fromPlace: searchData.from_stop.name,
        fromPlaceId: searchData.from_stop.id,
        toPlace: searchData.to_stop.name,
        toPlaceId: searchData.to_stop.id,
        searchDate: searchData.searchDate,
        searchTime: searchData.searchTime
    } : {};

    return (
        <div className="travel-planner-main">
            {!searchData && (
                <p id="travel-planner-body-text" className="tp-ai-description">
                    Movai er en ai-drevet reiseplattform som er utviklet og trent på norske kollektivdata.<br />
                    Plattformen er for tiden i en offentlig alpha-fase mens AI modellene ferdigstilles.<br />
                </p>
            )}
            <AnimatedPlaceholderInput setQuery={setQuery} />
            <div className="travel-planner-output">
                {searchData && (
                    <SearchModifyForm
                        searchData={searchData}
                        onFormSearch={(formData) => handleModifyFormSearch(formData)}
                    />
                )}
                {notice && <TravelPlannerMessage messageContent={notice} />}
                <LoadingIndicator isLoading={loading} loadingText="Søker etter reiser..." />
                {Object.keys(trips).length > 0 && (
                    Object.keys(trips).map((date, index) => (
                        <div key={index}>
                            <h2>{date}</h2>
                            {trips[date].map((trip, tripIndex) => (
                                isMobile ? (
                                    <TripDetailsMobile key={tripIndex} trip={trip} />
                                ) : (
                                    <TripDetailsDesktop key={tripIndex} trip={trip} />
                                )
                            ))}
                        </div>
                    ))
                )}
                {(nextPageCursor || previousPageCursor) && (
                    <div className="travelplaner-page-cursor-container">
                        {previousPageCursor && (
                            <button onClick={() => handleModifyFormSearch(formData, previousPageCursor)}>
                                Forrige
                            </button>
                        )}
                        {nextPageCursor && (
                            <button onClick={() => handleModifyFormSearch(formData, nextPageCursor)}>
                                Neste
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TravelPlanner;
