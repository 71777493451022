import React from 'react';

// Define a mapping from namespace to full name
const namespaceMapping = {
  AKT: "Agder kollektivtrafikk",
  ATB: "AtB",
  AVI: "Avinor",
  BNR: "Bane NOR",
  BEF: "Beffen",
  BOR: "Boreal",
  BSR: "Bussring",
  BRA: "Brakar",
  NYC: "Bygdøyfergen",
  COL: "Color Line",
  TEL: "Farte",
  FJT: "Fjord Tours",
  FLI: "Flixbus",
  FLT: "Flytoget",
  FTR: "Flåm Travel",
  FLB: "Flåmsbana",
  OSC: "Forsvarsbygg",
  MOR: "Fram",
  GFS: "Geiranger Fjordservice",
  GLO: "GlobeOrbit100",
  GOA: "Go Ahead",
  GOF: "Go Fjords",
  HAF: "Hafjell Alpinsenter",
  HAV: "Havila",
  HUR: "Hurtigruten",
  HOG: "Høgsfjordferja",
  INN: "Innlandet",
  KOL: "Kolumbus",
  SOF: "Kringom",
  OIS: "MF Øisang",
  NWY: "NOR-WAY Bussekspress",
  NOR: "Nordland fylkeskommune",
  NBU: "Connect Bus Flybuss",
  NIA: "Norsk industrianlegg",
  VIP: "Oslo VIP Transporttjenester",
  RUT: "Ruter",
  SJV: "SJ",
  SJN: "SJ NORD",
  SKY: "Skyss",
  FIN: "Snelandia",
  STB: "Stadbussen",
  TID: "Tide",
  TRO: "Troms fylkestrafikk",
  TTS: "Torghatten",
  ULR: "Ulriken",
  UNI: "Unibuss",
  VOT: "Vestfold og Telemark",
  VIL: "Visit Lillehammer",
  VKT: "VKT",
  VOG: "Voss Gondol",
  NSB: "Vy",
  GJB: "Vy Gjøvikbanen",
  VYG: "Vy-group",
  VYB: "Vy Buss AB",
  VYX: "Vy Buss AS",
  OST: "Østfold kollektivtrafikk",
  ATU: "Ålesund Turvogn Service"
};

const NamespaceTranslator = ({ namespace }) => {
  const fullName = namespaceMapping[namespace] || "Unknown";

  return fullName;
};

export default NamespaceTranslator;
