import React, { useEffect, useState } from 'react';
import Header from '../components/common/Header';
import TravelPlanner from '../components/travelplanner/TravelPlanner';
import { useSearchParams } from 'react-router-dom';

const TravelPlannerPage = () => {
    const [searchParams] = useSearchParams();
    const [initialSearchData, setInitialSearchData] = useState(null);

    useEffect(() => {
        const fromPlace = searchParams.get('fromPlace');
        const toPlace = searchParams.get('toPlace');
        const formattedDate = searchParams.get('formattedDate');

        if (fromPlace && toPlace && formattedDate) {
            const parsedDate = formattedDate.split('-');
            const travelDate = new Date(
                `20${parsedDate[2]}`,
                parsedDate[1] - 1,
                parsedDate[0],
                parsedDate[3],
                parsedDate[4]
            ).toISOString();

            setInitialSearchData({ fromPlace, toPlace, travelDate });
        }
    }, [searchParams]);

    return (
        <div>
            <Header />
            <div className="travel-planner-page">
                <h1>Reiseplanlegger</h1>

                <TravelPlanner initialSearchData={initialSearchData} />
            </div>
        </div>
    );
};

export default TravelPlannerPage;
